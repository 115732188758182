import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/fr"
import TeamCommitteePageTemplate from "../../templates/team-committee-page"

const CommitteeFrench = props => {
  const { title, subtitle } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title} description={subtitle}>
      <TeamCommitteePageTemplate {...props} />
    </Layout>
  )
}

export default CommitteeFrench

export const pageQuery = graphql`
  query CommitteeFrench {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/committee/index.fr.md/" }
    ) {
      frontmatter {
        ...ContributorsTeamCommitteePages
      }
    }
  }
`
