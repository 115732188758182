import React, { useState } from "react"
import PropTypes from "prop-types"

import {
  Banner,
  CategoryButtons,
  ContentWrapper,
  GridLayout,
} from "../components"

const TeamCommitteePageTemplate = ({ data }) => {
  const { title, subtitle, categories, banner } =
    data.markdownRemark.frontmatter

  const [selectedCategory, setSelectedCategory] = useState("")

  const filteredCategories =
    selectedCategory === ""
      ? categories
      : categories.filter(item => item.title === selectedCategory)

  return (
    <>
      <ContentWrapper title={title} subtitle={subtitle}>
        <CategoryButtons
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <GridLayout categories={filteredCategories} />
      </ContentWrapper>
      <Banner data={banner} />
    </>
  )
}

TeamCommitteePageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TeamCommitteePageTemplate
